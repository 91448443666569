<template>
<div>
    <div class="mb-2 alert alert-warning">
        <h5 class="alert-heading mb-0">Your email address is not verified! </h5>
        <div class="py-3">
            <span class="mr-4 font-14">To be notified when new order arrives, please check your inbox and verify your account. 
            <a target="_blank" :href="absoluteUrl(`/email/${activeUser.email}/verify`)" class="alert-link"> Click to verify now!</a></span>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name:"user-email-verification",
    computed: {
        activeUser(){
            return this.$store.state.activeUser
        }
    },
}
</script>
