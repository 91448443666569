<template>
    <div class="dashboard py-5 py-lg-6">
        <section v-if="activeUser">
            <div class="row">
                <div v-if="activeUser.id && !activeUser.is_verified" class="col-lg-12">
                    <user-email-verification />                   
                </div>
                <div class="col-lg-12 mb-5">
                    <div class="card shadow-sm show-worker-earnings p-5 border">
                        <div class="row g-4 text-center font-weight-bold justify-content-between">
                            <div class="col-6 col-md-3">
                                <p class="mb-0">TOTAL INCOME</p>
                                <h2 class="mb-0 text-success">${{earning.income | money_format}}</h2>
                            </div>
                            <div  class="col-6 col-md-3">
                                <p class="mb-0">WORKER GAIN</p>
                                <h2 class="mb-0 text-success">${{earning.gain | money_format}}</h2>
                            </div>
                            <div  class="col-6 col-md-3">
                                <p class="mb-0">PENDING</p>
                                <h2 class="mb-0 text-success">${{earning.pending | money_format}}</h2>
                            </div>
                            <div class="col-6 col-md-3">
                                <p class="mb-0">WITHDRAWN</p>
                                <h2 class="mb-0 text-success">${{earning.withdrawn | money_format}}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-8">
                    <div>
                        <div class="media d-sm-flex d-block">
                            <img :src="absoluteUrl(activeUser.image)" class="user-photo mr-3 rounded-circle" alt="user-photo">
                            <div class="media-body">
                                <h3 class="mb-0 mt-3 text-capitalize font-weight-bold">{{activeUser.username}}</h3>
                                <div>
                                    <img v-for="(ir, iIdex) in activeUser.rating"
                                    class="mr-1" :key="iIdex" width="15" src="/images/icons/dark-star.png">
                                    Avg. Score
                                </div>
                                <table class="table profile-table bg-transparent table-borderless">
                                    <tbody>
                                        <tr>
                                            <td scope="row"><strong>Country</strong></td>
                                            <td class="font-13" v-if="activeUser.country">{{activeUser.country.name}}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row"><strong>Language(s)</strong></td>
                                            <td class="font-13">{{activeUser.languages}}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row"><strong>Joined since</strong></td>
                                            <td class="font-13" colspan="2">{{activeUser.created_at | month_year}}</td>
                                        </tr>
                                        <tr>
                                            <td scope="row"><strong>Avg. response time</strong></td>
                                            <td class="font-13" colspan="2">{{activeUser.response_time}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="show-worker-summary">
                        <h4 class="fs-18 lh-base fw-bold mb-0"><span class="text-success">Summary</span></h4>
                        <p class="mb-0 mt-2 pt-1 text-muted">There is a total of <strong>{{result.orders.total}}</strong> order{{pluralize(result.orders.total)}} available in your account.
                            <strong>{{result.orders.active}}</strong> {{pluralizeIsAre(result.orders.active)}} active, <strong>{{result.orders.delivered}}</strong> {{pluralizeIsAre(result.orders.delivered)}} delivered, and
                            <strong>{{result.orders.completed}}</strong> {{pluralizeIsAre(result.orders.completed)}} completed. You have delivered on {{result.deliveries.total}} order{{pluralize(result.deliveries.total)}}. 
                            <strong>{{result.deliveries.progress}}</strong> {{pluralizeIsAre(result.deliveries.progress)}} in progress. You were choosen as Finalist in 
                            <strong>{{result.deliveries.finalist}}</strong> order{{pluralize(result.deliveries.finalist)}}. You won <strong>{{result.deliveries.winner}}</strong> order{{pluralize(result.deliveries.winner)}}. You were blocked on
                            <strong>{{result.deliveries.blocked}}</strong> order{{pluralize(result.deliveries.blocked)}}, and eliminated in <strong>{{result.deliveries.eliminated}}</strong> order{{pluralize(result.deliveries.eliminated)}} </p>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="row pt-5 pt-lg-6">
                <div class="col-12">
                    <order-table />
                </div>
            </div>
        </section>
    </div>
</template>

<script>

import OrderTable from "@/components/order/OrderTable.vue"
import UserEmailVerification from "@/views/account/UserEmailVerification.vue"

export default {
    name: 'dashboard',
    components:{
        OrderTable,
        UserEmailVerification
    },
    data(){
        return{
            result: {
                "orders": {
                    "total": 0,
                    "active": 0,
                    "delivered": 0,
                    "completed": 0
                },
                "deliveries": {
                    "total": 0,
                    "progress": 0,
                    "finalist": 0,
                    "winner": 0,
                    "blocked": 0,
                    "eliminated": 0
                }
            },
        }
    },
    computed:{
        activeUser(){
            return this.$store.state.activeUser
        },
        earning(){
            return this.activeUser.earning
                ? this.activeUser.earning
                : {
                    income: 0,
                    gain: 0,
                    pending: 0,
                    withdrawn: 0,
                  }
        },
        pluralize(){
            return (i) => i > 1 ? 's':''
        },
        pluralizeIsAre(){
            return (i) => i > 1 ? 'are':'is'
        },
    },
    created(){
        this.$http.get('/analytics')
            .then((response) => {
                if(response.data.success){
                    this.result = response.data.data.result;
                }
            })
    }

}
</script>

<style scoped>
.user-photo{
    width:10rem;
}
@media(max-width:567px){
    .user-photo{
        width:4rem;
    }
}
.dashboard .table.profile-table td, .dashboard .table.profile-table th{
    padding:0.2rem;
}
.show-worker-summary{
    border-left:2px solid #ccc;
    padding-left:1rem;
    height: 100%;
}

.show-worker-earnings h2{
    font-weight: bold;
}
@media(max-width:567px){
    .show-worker-earnings h2{
        font-size:22px;
    }
    .show-worker-earnings p{
        font-size:14px;
    }
}
</style>
